import { render, staticRenderFns } from "./ColrList.vue?vue&type=template&id=2eb72eb6&scoped=true&"
import script from "./ColrList.vue?vue&type=script&lang=js&"
export * from "./ColrList.vue?vue&type=script&lang=js&"
import style0 from "./ColrList.vue?vue&type=style&index=0&id=2eb72eb6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2eb72eb6",
  null
  
)

export default component.exports