<template>
  <el-card>
    <el-form :model="searchForm" :inline="true">
      <el-row>
        <el-col :md="8">
          <el-form-item prop="colr_pantone" label="潘通色号：">
            <el-input v-model="searchForm.colr_pantone" size="small" placeholder="潘通色号" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item prop="colr_class" label="颜色大类：">
            <!-- <el-input ></el-input> -->
            <el-select v-model="searchForm.colr_class" size="small" placeholder="请选择" clearable>
              <el-option v-for="item in optnColorList" :key="item.id" :label="item.param1" :value="item.param1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" icon="el-icon-search" class="vg_ml_16" @click="clickSearchForm">查询</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-button size="small" type="primary" @click="clickSaves">保存</el-button>
    <el-table :data="colorList" border class="vg_mt_16" @selection-change="selectChange" v-loading="loading">
      <el-table-column type="selection"></el-table-column>
      <el-table-column type="index" align="center" label="序号" width="58"></el-table-column>
      <el-table-column prop="colr_pantone" label="潘通色号" align="center"></el-table-column>
      <el-table-column prop="colr_html" label="html颜色" align="center">
        <template slot-scope="scope">
          {{ scope.row.colr_html }}
          <div class="square" :style="{ backgroundColor: scope.row.colr_html }"></div>
        </template>
      </el-table-column>
      <el-table-column prop="colr_class" label="颜色大类" align="center">
        <template slot-scope="scope">
          <el-select v-model="scope.row.colr_class" size="small" placeholder="请选择">
            <el-option v-for="item in optnColorList" :key="item.id" :label="item.param1" :value="item.param1"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <!-- <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button type="primary"  size="small" @click="clickSave(scope.row)">保存</el-button>
                </template>
            </el-table-column> -->
    </el-table>
    <pubPagination :totalPage="total" @changePageSearch="changePageSearch" ref="pubPagination" :pageSize="100" />
  </el-card>
</template>

<script>
import { colrAPI } from '@/api/modules/colr.js';
import { optnAPI } from '@/api/modules/optn.js';
import { get, post } from '@/api/request.js';
import pubPagination from '@/components/common/pubPagination';
export default {
  name: 'ColrList',
  components: {
    pubPagination
  },
  data() {
    return {
      searchForm: {
        page_no: 1,
        colr_pantone: null,
        colr_class: null
      },
      colorList: [],
      total: 0,
      loading: false,
      optnColorList: [],
      multab: []
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getColorList();
      this.getOptnColor();
    },
    getColorList() {
      this.loading = true;
      get(colrAPI.getColrs, this.searchForm)
        .then(res => {
          if (res.data.code === 0) {
            this.total = res.data.data.total;
            this.colorList = res.data.data.list;
            setTimeout(() => {
              this.loading = false;
            }, 300);
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.mst);
        });
    },
    getOptnColor() {
      get(optnAPI.getAllContent, { perm_id: 10010 })
        .then(res => {
          if (res.data.code === 0) {
            this.optnColorList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    clickSearchForm() {
      this.$refs.pubPagination.currentPage = 1;
      this.searchForm.page_no = 1;
      this.getColorList();
    },
    changePageSearch(val) {
      this.searchForm.page_no = val;
      this.getColorList();
    },
    // clickSave(row){
    //     post(colrAPI.editColr,{colr_id:row.colr_id,colr_class:row.colr_class})
    //     .then(res =>{
    //         if(res.data.code===0){
    //             this.$message.success(res.data.msg)
    //             return
    //         }
    //         this.$message.error(res.data.msg)
    //     })
    //     .catch(res =>{
    //         this.$message.error(res.data.msg)
    //     })
    // },
    selectChange(val) {
      this.multab = val;
    },
    clickSaves() {
      if (this.multab.length === 0) {
        this.$message.warning('至少选择一条信息');
        return;
      }
      this.$confirm('确定保存?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let arr = [];
          this.multab.forEach(item => {
            arr.push({
              colr_id: item.colr_id,
              colr_class: item.colr_class
            });
          });
          post(colrAPI.editColrs, { colr_list: arr })
            .then(res => {
              if (res.data.code === 0) {
                this.$message.success(res.data.msg);

                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(res => {});
    }
  }
};
</script>

<style lang="scss" scoped>
.square {
  width: 50px;
  height: 15px;
  display: inline-block;
}
</style>
